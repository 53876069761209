<template>
  <div id="home">
    <NavBar :currentRoute="'Info'"/>

    <v-container class="flex" id="main-container">
      <v-row justify="center">
        <v-col cols="12" md="11" lg="11" xl="10">
          <Company/>
        </v-col>
      </v-row>
    </v-container>

    <About/>
  </div>
</template>

<script>
import About from "@/components/About";
import NavBar from "@/components/NavBar";
import Company from "@/components/Company";

export default {
  name: 'CompanyView',
  components: {
    NavBar,
    About,
    Company
  }
}
</script>

<style scoped>
#home {
  background-color: #f3dfd2;
  background-size: 100vw 100vh;
  height: 100vh;
}
</style>
