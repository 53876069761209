<template>
  <v-card class="mx-auto" color="rgba(141,110,99, 0.85)" dark max-width="600" img="">
    <v-card-title>
      <v-container>
        <v-row justify="center">
          <v-col cols="auto">
            <v-icon x-large left>mdi-office-building-cog-outline</v-icon>
            <span class="text-h4">{{ company.name }}</span>
          </v-col>
        </v-row>
      </v-container>
    </v-card-title>

    <v-card-text class="text-h5 font-weight-bold">

      <v-container>
        <table cellspacing="15px" style="margin-left: auto;margin-right: auto; line-height:40px;">
          <tr>
            <td>
              <v-icon left>mdi-calendar-clock</v-icon>
              <span class="subtitle-1">Next payment:</span>
            </td>
            <td>
              <span class="text-h6 font-weight-bold">{{ company.nextPayment }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <v-icon left>mdi-cash</v-icon>
              <span class="subtitle-1">Price / month: </span>
            </td>
            <td>
              <span class="text-h6 font-weight-bold">{{ company.pricePerMonth }} €</span>
            </td>
          </tr>
          <tr>
            <td>
              <v-icon left>mdi-account-multiple-check</v-icon>
              <span class="subtitle-1">Max users:</span>
            </td>
            <td>
              <span class="text-h6 font-weight-bold">{{ company.maxUsers }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <v-icon left>mdi-calendar-multiple</v-icon>
              <span class="subtitle-1">Registered:</span>
            </td>
            <td>
              <span class="text-h6 font-weight-bold">{{ company.creationDate }}</span>
            </td>
          </tr>
        </table>
      </v-container>

    </v-card-text>

    <v-overlay :value="showOverlay" color="#B4B4B4">
      <v-progress-circular indeterminate size="50" color="amber"/>
    </v-overlay>

  </v-card>
</template>

<script>
import CompanyService from "@/service/CompanyService";

export default {
  name: "Company",
  async created() {
    let company;
    try {
      company = await CompanyService.getInfo();
    } catch (e) {
      this.errorMessage = e.message;
      return;
    } finally {
      this.showOverlay = false;
    }
    this.company = company;
  },
  data() {
    return {
      errorMessage: '',
      showOverlay: true,
      company: {
        "id": 1,
        "name": "",
        "maxUsers": 0,
        "nextPayment": "",
        "creationDate": "",
        "pricePerMonth": 0
      }
    }
  },
}
</script>